.container {
	position: relative;
	display: inline-block;
}

.box {
	position: absolute;
	background-color: black;
	color: white;
	padding: 5px;
	border-radius: 4px;
	font-size: 14px;
	white-space: nowrap;
}

.top {
	bottom: 100%;
	left: 50%;
	transform: translateX(-50%);
}

.bottom {
	top: 100%;
	left: 50%;
	transform: translateX(-50%);
}

.right {
	top: 50%;
	left: 100%;
	transform: translateY(-50%);
}